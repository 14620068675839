import MomentUtils from "@date-io/moment";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import withAPI from "../../services/api";
import CloseAssignmentConfirmModal from "./CloseAssignmentConfirmModal";
import "./EditAssignmentModal.scss";
import ClosePopupBtn from "../UtilComps/ClosePopupBtn";

const EditAssignmentModal = ({
  api,
  shouldShowFunc,
  assignment,
  fetchAssignments,
  setShowSyncReminder,
}) => {
  const [endDateError, setEndDateError] = useState(false);
  const [validStartDateErr, setValidStartDateErr] = useState(false);
  const [validEndDateErr, setValidEndDateErr] = useState(false);
  const [closeAssignment, setCloseAssignment] = useState(
    assignment.status === "closed" ? true : false
  );
  const [newName, setNewName] = useState(assignment.name);
  const [newStartTime, setNewStartTime] = useState(assignment.start_time);
  const [newEndTime, setNewEndTime] = useState(assignment.end_time);
  const [confirmClose, setConfirmClose] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [pastDateRangeError, setPastDateRangeError] = useState(false);

  useEffect(() => {
    let startTimeIsValid = moment(newStartTime).isValid();
    let endTimeIsValid =
      newEndTime === "" || newEndTime === null || moment(newEndTime).isValid();

    if (!startTimeIsValid) {
      setValidStartDateErr(true);
    } else {
      setValidStartDateErr(false);
    }

    if (!endTimeIsValid) {
      setValidEndDateErr(true);
    } else {
      setValidEndDateErr(false);
    }

    if (moment(newStartTime) > moment(newEndTime) && newEndTime) {
      // due date is before start date.
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }

    if (
      startTimeIsValid &&
      endTimeIsValid &&
      moment(newStartTime).isBefore() &&
      moment(newEndTime).isBefore() &&
      !moment(newEndTime).isSame(new Date(), "day")
    ) {
      // the entire date range is in the past.
      setPastDateRangeError(true);
    } else {
      setPastDateRangeError(false);
    }
  }, [newEndTime, newStartTime]);

  const handleSubmit = () => {
    console.log(newEndTime);
    setErrorMsg("");
    let newData = {
      name: newName,
      start_time: newStartTime,
      end_time: newEndTime,
      status: closeAssignment ? "closed" : "in_progress",
    };

    api
      .editAssignment(assignment.id, newData)
      .then((res) => {
        console.log(res);
        fetchAssignments();
        setShowSyncReminder(true);
        shouldShowFunc(false);
      })
      .catch((e) => {
        console.log(e);
        setErrorMsg(
          "An error occurred while saving your changes. Please try again later."
        );
      });
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setCloseAssignment(isChecked);
    setConfirmClose(isChecked);
  };

  return (
    <div className='pdf-upload'>
      <div className='pdf-upload-wrapper-large'>
        <div className='common_border'>
          <div className='common_heading'>
            <p>Edit {assignment.name}</p>
            <ClosePopupBtn closePopupFunc={shouldShowFunc} />
          </div>

          {confirmClose && (
            <CloseAssignmentConfirmModal
              setCloseAssignment={setCloseAssignment}
              shouldShowFunc={setConfirmClose}
            />
          )}

          <div className='common_dashboard_bg'>
            <div className='row'>
              <div className='col-12'>
                {errorMsg ? (
                  <div className='alert alert-danger' role='alert'>
                    {errorMsg}
                  </div>
                ) : null}

                <div
                  id='edit_assignment_modal_fields_grid'
                  style={{ gridArea: "a" }}
                >
                  <div
                    className='edit_assignment_field_container'
                    style={{ gridArea: "a" }}
                  >
                    <p className='edit_assignment_field_container_caption'>
                      Assignment Name:
                    </p>

                    <TextField
                      onChange={(e) => {
                        setNewName(e.target.value);
                      }}
                      value={newName}
                      inputProps={{ maxLength: 40 }}
                    />
                  </div>

                  <div style={{ gridArea: "c" }}>
                    <div id='edit_assignment_timeline_container'>
                      <p
                        className='edit_assignment_field_container_caption timeframe_caption'
                        style={{ gridArea: "a", marginBottom: "0px" }}
                      >
                        Timeframe:
                      </p>
                      <div
                        className='edit_assignment_timeline_picker'
                        style={{ gridArea: "d" }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          utils={MomentUtils}
                        >
                          <DesktopDatePicker
                            label='Start Date'
                            value={newStartTime}
                            disabled={
                              closeAssignment ||
                              assignment.status === "closed" ||
                              assignment.status === "completed"
                            }
                            onChange={(value) => {
                              setNewStartTime(value);
                            }}
                            inputFormat='MMM. dd, yyyy'
                            renderInput={(params) => (
                              <TextField
                                helperText={
                                  validStartDateErr
                                    ? "Please enter a valid date."
                                    : endDateError
                                    ? "Due date cannot be before the start date."
                                    : pastDateRangeError
                                    ? "The date range must be current or in the future."
                                    : ""
                                }
                                {...params}
                              />
                            )}
                            onError={(reason, value) => {
                              console.log("reason:", reason);
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div
                        id='edit_assignment_timeline_arrow'
                        style={{ gridArea: "e" }}
                      >
                        <hr></hr>
                        <p>
                          <i className='arrow right'></i>
                        </p>
                      </div>
                      <div
                        className='edit_assignment_timeline_picker'
                        style={{ gridArea: "f" }}
                      >
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          utils={MomentUtils}
                        >
                          <DesktopDatePicker
                            label='Due Date (Optional)'
                            minDate={newStartTime}
                            value={newEndTime}
                            disabled={
                              closeAssignment ||
                              assignment.status === "completed"
                            }
                            onChange={(value) => {
                              setNewEndTime(value);
                            }}
                            inputFormat='MMM. dd, yyyy'
                            renderInput={(params) => (
                              <TextField
                                helperText={
                                  !newEndTime
                                    ? ""
                                    : validEndDateErr
                                    ? "Please enter a valid date."
                                    : endDateError
                                    ? "Due date cannot be before the start date."
                                    : pastDateRangeError
                                    ? "The date range must be current or in the future."
                                    : ""
                                }
                                {...params}
                              />
                            )}
                            onError={(reason, value) => {
                              console.log("reason:", reason);
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>

                  <div id='close_assignment_checkbox_container'>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={closeAssignment}
                            disabled={assignment.status === "completed"}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label='Close Assignment'
                      />
                    </FormGroup>
                    {closeAssignment ? (
                      <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        This assignment is closed.
                      </p>
                    ) : assignment.status === "completed" ? (
                      <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        This assignment is completed.
                      </p>
                    ) : (
                      <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        This assignment is open.
                      </p>
                    )}
                  </div>

                  {newStartTime &&
                    moment(newStartTime).isAfter() &&
                    !assignment.status === "completed" && (
                      <p
                        style={{
                          width: "100%",
                          margin: "0px",
                          textAlign: "left",
                        }}
                        className='alert alert-secondary'
                      >
                        Note: Learners won't be able to complete the assignment
                        until the start date.
                      </p>
                    )}
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    gridGap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ width: "fit-content", margin: "auto" }}
                    disabled={
                      endDateError ||
                      validEndDateErr ||
                      validStartDateErr ||
                      pastDateRangeError
                    }
                    onClick={handleSubmit}
                    className='btn btn-sm btn-primary'
                  >
                    Save
                  </button>
                  <button
                    style={{ width: "fit-content", margin: "auto" }}
                    onClick={() => shouldShowFunc(false)}
                    className='btn btn-sm btn-danger'
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter, withAPI)(EditAssignmentModal);

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { useDebounce } from "use-debounce";
import { useTitle } from "../../common/utils";
import CreateOrgForm from "../../components/SuperAdmin/CreateOrgForm";
import OrgContactsModal from "../../components/SuperAdmin/OrgContactsModal";
import PaginationBar from "../../components/UtilComps/PaginationBar.js";
import FA from "../../containers/fa";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";
import CheckboxFilter from "../ManageSubscriptions/Filters/CheckboxFilter";
import "./Dashboard.scss";
import "./OrgsList.scss";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const OrgsList = ({ api, user, history }) => {
  useTitle("Organizations");

  const [orgs, setOrgs] = useState([]);
  const [ready, setReady] = useState(false);
  const [historyLoaded, setHistoryLoaded] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [pageSize, setPageSize] = useState(null);

  const [sortType, setSortType] = useState("name");
  const [sortAsc, setSortAsc] = useState(false);

  const [filterString, setFilterString] = useState("");
  const [filterState, setFilterState] = useState("");
  const [filterOrgLevel, setFilterOrgLevel] = useState("");
  const [filterCategory, setFilterCategory] = useState("school");
  const [filterNoRobots, setFilterNoRobots] = useState(false);
  const [filterNeedsAttention, setFilterNeedsAttention] = useState(false);
  const [filterExpiredRobots, setFilterExpiredRobots] = useState(false);
  const [filterExpiringRobots, setFilterExpiringRobots] = useState(false);
  const [filterExcludeExpiredRobots, setFilterExcludeExpiredRobots] =
    useState(false);
  const [overallChange, setOverallChange] = useState(0);
  const [debouncedOverallChange] = useDebounce(overallChange, 1000);

  const [orgSummaryStats, setOrgSummaryStats] = useState(null);
  const [orgContactsViewingId, setOrgContactsViewingId] = useState(null);
  const [showCreateOrgForm, setShowCreateOrgForm] = useState(false);

  const isSuperAdmin = () => {
    if (user) {
      if (user.usertype === "superadmin") {
        return true;
      }
    }
    return false;
  };

  const handleExcludeFilterChange = (val) => {
    setFilterExcludeExpiredRobots(val);
    setFilterExpiredRobots(false);
    setFilterExpiringRobots(false);
  };
  const handleExpiringRobotsFilterChange = (val) => {
    setFilterExpiringRobots(val);
    setFilterExcludeExpiredRobots(false);
    setFilterExpiredRobots(false);
  };
  const handleExpiredRobotsFilterChange = (val) => {
    setFilterExpiredRobots(val);
    setFilterExcludeExpiredRobots(false);
    setFilterExpiringRobots(false);
  };

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.from === "usage_stat"
    ) {
      setSortType("perc_robots_active");
      setSortAsc(true);
    }
    setHistoryLoaded(true);
  }, [history]);

  const fetchAllOrgs = (pageNum, forceCalcStats = false) => {
    const calcStats =
      forceCalcStats || (orgSummaryStats === null ? true : false);
    let queryParams = buildQueryParamsForFetchAllOrgs(calcStats);
    if (pageNum) {
      queryParams["page"] = pageNum;
    }

    // skip nulls,  empty strings, false values
    queryParams = Object.entries(queryParams).filter(([k, v]) => v);

    if (debouncedOverallChange > 0) {
      // This will also be the on-load call
      setReady(false);
      api.fetchAllVisibleOrganizations(queryParams).then((objects) => {
        console.log(objects.results);
        setOrgs(objects.results);
        delete objects["results"];
        if (calcStats) {
          setOrgSummaryStats(objects.stats);
        }
        delete objects["stats"];
        setPaginationInfo(objects);
        setReady(true);
      });
    }
  };

  const buildQueryParamsForFetchAllOrgs = (calcStats) => {
    let queryParams = {
      sort: (sortAsc ? "" : "-") + sortType,
      q: filterString,
      state: filterState,
      org_level: filterOrgLevel,
      needs_attention: filterNeedsAttention,
      get_orgs_with_no_robots: filterNoRobots,
      calc_stats: calcStats,
      page_size: pageSize,
    };
    const robotFilterParams = buildQueryParamsForExportSubscriptions();

    return Object.assign(queryParams, robotFilterParams);
  };

  const handleExportSubscriptionsBtnClick = () => {
    const queryParams = buildQueryParamsForExportSubscriptions();
    api.exportSubsWithRobotCount(queryParams);
  };

  const buildQueryParamsForExportSubscriptions = () => {
    const queryParams = {
      get_orgs_with_expired_robots: filterExpiredRobots,
      get_orgs_with_expiring_robots: filterExpiringRobots,
      get_orgs_without_expired_robots: filterExcludeExpiredRobots,
      category: filterCategory,
    };
    return queryParams;
  };

  useEffect(() => {
    console.log("org id is", orgContactsViewingId);
  }, [orgContactsViewingId]);

  useEffect(() => {
    fetchAllOrgs(null, true);
  }, [debouncedOverallChange]);

  // sort & page size: no need to redo calcs
  useEffect(() => {
    if (historyLoaded) {
      fetchAllOrgs(null);
    }
  }, [sortType, sortAsc, pageSize]);

  useEffect(() => {
    setOverallChange(overallChange + 1);
  }, [
    filterState,
    filterString,
    filterCategory,
    filterOrgLevel,
    filterNoRobots,
    filterNeedsAttention,
    filterExpiredRobots,
    filterExpiringRobots,
    filterExcludeExpiredRobots,
  ]);

  const resetFilters = () => {
    setFilterString("");
    setFilterState("");
    setFilterCategory("");
    setFilterOrgLevel("");
    setFilterNoRobots(false);
    setFilterNeedsAttention(false);
    setFilterExpiredRobots(false);
    setFilterExpiringRobots(false);
    setFilterExcludeExpiredRobots(false);
  };

  const handleColumnHeaderClick = (column) => {
    if (sortType === column) {
      setSortAsc(!sortAsc);
    } else {
      setSortType(column);
      setSortAsc(false);
    }
  };

  const getHeaderClasses = (column) => {
    let classes = "sortable";
    if (sortType === column) {
      if (sortAsc === false) {
        classes += " headerSortDown";
      } else {
        classes += " headerSortUp";
      }
    } else {
      classes += " headerSort";
    }
    return classes;
  };

  return (
    <MainDashboard>
      <div className='common_border'>
        <div className='common_heading d-flex justify-content-between align-items-center'>
          <p>Organizations</p>
        </div>

        <div className='common_table_padding'>
          <div>
            {isSuperAdmin() ? (
              <div className='col-12'>
                <div className='mb-10'>
                  <div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                      }}
                    >
                      <button
                        className='btn btn-primary'
                        style={{ width: "fit-content", marginBottom: "10px" }}
                        onClick={() => {
                          setShowCreateOrgForm(true);
                        }}
                      >
                        Create New Org...
                      </button>
                      <button
                        className='btn btn-primary'
                        style={{
                          width: "fit-content",
                          justifySelf: "end",
                          marginBottom: "10px",
                        }}
                        onClick={() => history.goBack()}
                      >
                        Back
                      </button>
                    </div>
                    {showCreateOrgForm && (
                      <CreateOrgForm
                        setShowCreateOrgForm={setShowCreateOrgForm}
                      />
                    )}
                  </div>

                  <button
                    className='btn btn-primary'
                    style={{ width: "fit-content", marginBottom: "10px" }}
                    onClick={() => {
                      handleExportSubscriptionsBtnClick();
                    }}
                  >
                    Export Robot Subscriptions
                  </button>

                  <div className='table-filter-wrapper p-3 w-100 mb-2'>
                    <div id='filters_fields_container'>
                      <h5 style={{ marginBottom: "0px" }}>
                        Filter by Org Info:
                      </h5>
                      <div className='row-1'>
                        <TextField
                          margin='normal'
                          placeholder='Search ...'
                          disabled={!ready}
                          size='medium'
                          value={filterString}
                          onChange={(e) =>
                            setFilterString(e.target.value.toLowerCase())
                          }
                        />

                        <TextField
                          margin='normal'
                          placeholder='State'
                          disabled={!ready}
                          size='medium'
                          value={filterState}
                          onChange={(e) => {
                            setFilterState(e.target.value);
                          }}
                        />

                        <FormControl>
                          <InputLabel id='demo-simple-select-label'>
                            Category:
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            disabled={!ready}
                            value={filterCategory || ""}
                            onChange={(e) => {
                              setFilterCategory(e.target.value);
                              if (e.target.value === "home") {
                                setFilterOrgLevel("");
                              }
                            }}
                          >
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={"home"}>Home</MenuItem>
                            <MenuItem value={"school"}>School</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl>
                          <InputLabel id='demo-simple-select-label'>
                            Org Level:
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            disabled={filterCategory === "home" || !ready}
                            value={filterOrgLevel || ""}
                            onChange={(e) => {
                              setFilterCategory("school");
                              setFilterOrgLevel(e.target.value);
                            }}
                          >
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={"district"}>
                              District-level
                            </MenuItem>
                            <MenuItem value={"school"}>School-level</MenuItem>
                            <MenuItem value={"unspecified"}>
                              Unspecified
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <CheckboxFilter
                        disabled={!ready}
                        checkBoxHoverText='Only show orgs that have no robots.'
                        popupHeaderText='Organizations with No Robots'
                        filterLabel='Filter by orgs with no robots'
                        checked={filterNoRobots}
                        setChecked={setFilterNoRobots}
                        popupBodyText={`Filters for orgs with no robots `}
                      />

                      <CheckboxFilter
                        disabled={!ready}
                        checkBoxHoverText='Only show orgs requiring dev team cleanup.'
                        popupHeaderText='Organizations Requiring Dev Team Cleanup'
                        filterLabel='Filter by orgs that require dev team cleanup'
                        checked={filterNeedsAttention}
                        setChecked={setFilterNeedsAttention}
                        popupBodyText={`This should be used by the dev team to
                          filter for organizations that have robots without
                          subscriptions properly setup. This will allow the dev
                          team to further cleanup these organizations to be
                          compliant with our subscription flow. `}
                        popupBtnDangerouslySetHtmlText={`This includes robots
                          that:
                          <ul>
                          <li>have no activation code</li>
                          <li>have an activation code, but this code is not
                          tied to a subscription</li>
                          <li>are attached to a subscription, but that
                          subscription has no activation code</li>
                          </ul>`}
                      />
                    </div>
                    <div id='filters_fields_container'>
                      <h5 className='mt-4' style={{ marginBottom: "0px" }}>
                        Filter by Org Subscriptions:
                      </h5>
                      <div className='row-2'>
                        <CheckboxFilter
                          disabled={!ready}
                          filterLabel='Only show orgs with robots expiring before the next cycle'
                          checked={filterExpiringRobots}
                          setChecked={handleExpiringRobotsFilterChange}
                          popupHeaderText='Organizations with Expiring Robot Subscriptions'
                          popupBodyText='Filters for orgs with robot subscriptions that will expire before either Jan or Aug 15, whichever comes first.'
                        />
                        <CheckboxFilter
                          disabled={!ready}
                          filterLabel='Only show orgs with expired robots'
                          checked={filterExpiredRobots}
                          setChecked={handleExpiredRobotsFilterChange}
                          popupHeaderText='Organizations with Expired Robot Subscriptions'
                          popupBodyText='Filters for orgs with expired robot subscriptions.'
                        />
                        <CheckboxFilter
                          disabled={!ready}
                          filterLabel='Hide orgs with expired robots'
                          checked={filterExcludeExpiredRobots}
                          setChecked={handleExcludeFilterChange}
                          popupHeaderText='Organizations with Active Robot Subscriptions'
                          popupBodyText='Filters for orgs with active robot subscriptions.'
                        />
                      </div>
                      <button
                        className='btn btn-primary btn-sm mt-1 reset_filters_btn'
                        disabled={!ready}
                        onClick={() => {
                          resetFilters();
                        }}
                      >
                        Reset All Filters
                      </button>
                    </div>
                  </div>

                  {orgSummaryStats && (
                    <div className='common_border w-100 mb-2'>
                      <div className='common_heading d-flex justify-content-between align-items-center'>
                        <span>
                          <span className='item-label'>Total Orgs:</span>
                          <span className='ml-2'>
                            {orgSummaryStats.total_orgs}
                          </span>
                        </span>
                        <span>
                          <span className='item-label'>Total Districts:</span>
                          <span className='ml-2'>
                            {orgSummaryStats.total_districts}
                          </span>
                        </span>
                        <span>
                          <span className='item-label'>Total Schools:</span>
                          <span className='ml-2'>
                            {orgSummaryStats.total_schools}
                          </span>
                        </span>
                        <span>
                          <span className='item-label'>Total Homes:</span>
                          <span className='ml-2'>
                            {orgSummaryStats.total_homes}
                          </span>
                        </span>
                      </div>
                    </div>
                  )}

                  <PaginationBar
                    paginationInfo={paginationInfo}
                    fetchPageFunc={fetchAllOrgs}
                    disabled={!ready}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                    loading={!ready}
                  />

                  <div className='card w-100'>
                    <div
                      className='progress-linear school_listing manage_learner_table table-scroll-wrapper'
                      role='progressbar'
                    >
                      <div id='orgs_list_table' className='table table-striped'>
                        <div id='orgs_list_table_hr_row'>
                          <div className='orgs_list_table_data_hr'>#</div>
                          <div
                            className='orgs_list_table_data_hr'
                            onClick={() => {
                              handleColumnHeaderClick("name");
                            }}
                          >
                            <p
                              className={`${getHeaderClasses(
                                "name"
                              )} th_sortable`}
                              style={{ justifyContent: "flex-start" }}
                            >
                              Org Name
                            </p>
                          </div>
                          <div
                            className='orgs_list_table_data_hr'
                            onClick={() => {
                              handleColumnHeaderClick("state");
                            }}
                          >
                            <p
                              className={`${getHeaderClasses(
                                "state"
                              )} th_sortable`}
                            >
                              State
                            </p>
                          </div>
                          <div
                            className='orgs_list_table_data_hr'
                            onClick={() => {
                              handleColumnHeaderClick("category");
                            }}
                          >
                            <p
                              className={`${getHeaderClasses(
                                "category"
                              )} th_sortable`}
                            >
                              Category
                            </p>
                          </div>
                          <div
                            className='orgs_list_table_data_hr'
                            onClick={() => {
                              handleColumnHeaderClick("org_level");
                            }}
                          >
                            <p
                              className={`${getHeaderClasses(
                                "org_level"
                              )} th_sortable`}
                            >
                              Level
                            </p>
                          </div>
                          <div
                            className='orgs_list_table_data_hr'
                            onClick={() => {
                              handleColumnHeaderClick("perc_robots_active");
                            }}
                          >
                            <p
                              className={`${getHeaderClasses(
                                "perc_robots_active"
                              )} th_sortable`}
                            >
                              % of Active Robots
                            </p>
                          </div>

                          <div
                            className='orgs_list_table_data_hr'
                            onClick={() => {
                              handleColumnHeaderClick("num_robots");
                            }}
                          >
                            <p
                              className={`${getHeaderClasses(
                                "num_robots"
                              )} th_sortable`}
                            >
                              # Robots
                            </p>
                          </div>

                          <div
                            className='orgs_list_table_data_hr'
                            onClick={() => {
                              handleColumnHeaderClick("num_teachers");
                            }}
                          >
                            <p
                              className={`${getHeaderClasses(
                                "num_teachers"
                              )} th_sortable`}
                            >
                              # of Teachers
                            </p>
                          </div>
                          <div
                            className='orgs_list_table_data_hr'
                            onClick={() => {
                              handleColumnHeaderClick("enrolled_learner_count");
                            }}
                          >
                            <p
                              className={`${getHeaderClasses(
                                "enrolled_learner_count"
                              )} th_sortable`}
                            >
                              # of Enrolled Learners
                            </p>
                          </div>
                          <div
                            className='orgs_list_table_data_hr hide_on_ipad'
                            style={{ textAlign: "center" }}
                          >
                            Actions
                          </div>
                        </div>

                        <div className='orgs_list_table_data_container'>
                          {orgs &&
                            orgs.map((org, index) => (
                              <div
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#f2f2f2" : "white",
                                }}
                              >
                                <div
                                  key={org.id}
                                  className='orgs_list_table_data_row'
                                >
                                  <div
                                    className='orgs_list_table_data'
                                    style={{ textAlign: "left" }}
                                  >
                                    {index + 1}
                                  </div>
                                  <div
                                    className='orgs_list_table_data'
                                    style={{ textAlign: "left" }}
                                  >
                                    <span>{org.name}</span>
                                  </div>
                                  <div className='orgs_list_table_data'>
                                    <span>{org.state}</span>
                                  </div>
                                  <div
                                    className='orgs_list_table_data'
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <span>{org.category}</span>
                                  </div>
                                  <div className='orgs_list_table_data'>
                                    {org.org_level &&
                                      org.org_level[0].toUpperCase()}
                                  </div>
                                  <div
                                    className={`${
                                      org.num_robots > 0
                                        ? org.perc_robots_active <= 0.25
                                          ? "orgs_list_table_data red"
                                          : org.perc_robots_active <= 0.75
                                          ? "orgs_list_table_data yellow"
                                          : org.perc_robots_active > 0.75
                                          ? "orgs_list_table_data green"
                                          : "orgs_list_table_data none"
                                        : "orgs_list_table_data none"
                                    }`}
                                  >
                                    {org.num_robots > 0 ? (
                                      <span style={{ fontWeight: "bold" }}>
                                        {Math.round(
                                          org.perc_robots_active * 100
                                        )}
                                        % ({org.num_robots_active || 0}/
                                        {org.num_robots}){" "}
                                        {org.num_robots > 0 ? (
                                          org.perc_robots_active <= 0.25 ? (
                                            <FA icon='times' color='#CB444A' />
                                          ) : org.perc_robots_active <= 0.75 ? (
                                            <FA
                                              icon='exclamation-circle'
                                              color='#FFC107'
                                            />
                                          ) : org.perc_robots_active > 0.75 ? (
                                            <FA icon='check' color='#1C842F' />
                                          ) : null
                                        ) : null}
                                      </span>
                                    ) : (
                                      <span>N/A</span>
                                    )}
                                  </div>
                                  <div className='orgs_list_table_data'>
                                    <span>{org.num_robots}</span>
                                  </div>
                                  <div className='orgs_list_table_data'>
                                    <span>{org.num_teachers}</span>
                                  </div>
                                  <div className='orgs_list_table_data'>
                                    <span>{org.enrolled_learner_count}</span>
                                  </div>
                                  <div
                                    className='orgs_list_table_data hide_on_ipad'
                                    style={{
                                      height: "fit-content",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "inline-flex",
                                        position: "relative",
                                      }}
                                    >
                                      <Link
                                        to={"/super/org/" + org.id}
                                        className='org-actions btn btn-sm btn-primary mr-2'
                                        assignment-data-title='View Org Page'
                                      >
                                        <FA icon='search' color='white' />
                                      </Link>

                                      {orgContactsViewingId === org.id ? (
                                        <OrgContactsModal
                                          org={org}
                                          isDashboardPage={true}
                                          setOrgContactsViewingId={
                                            setOrgContactsViewingId
                                          }
                                        />
                                      ) : (
                                        <div style={{ position: "relative" }}>
                                          <button
                                            className='org-actions btn btn-sm btn-primary'
                                            assignment-data-title='View Org Contact Info'
                                            onClick={() => {
                                              setOrgContactsViewingId(org.id);
                                            }}
                                          >
                                            <FA icon='phone' color='white' />
                                          </button>
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                </div>
                                {/* Will only show on iPad width and shorter */}
                                <div
                                  className='orgs_list_table_data show_on_ipad'
                                  style={{
                                    height: "fit-content",
                                    margin: "0px",
                                    padding: "0px 0px 10px 5px",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      position: "relative",
                                    }}
                                  >
                                    <Link
                                      to={"/super/org/" + org.id}
                                      className='org-actions btn btn-sm btn-primary mr-2'
                                      assignment-data-title='View Org Page'
                                    >
                                      <FA icon='search' color='white' />
                                    </Link>

                                    {orgContactsViewingId === org.id ? (
                                      <OrgContactsModal
                                        org={org}
                                        isDashboardPage={true}
                                        setOrgContactsViewingId={
                                          setOrgContactsViewingId
                                        }
                                      />
                                    ) : (
                                      <div style={{ position: "relative" }}>
                                        <button
                                          className='org-actions btn btn-sm btn-primary'
                                          assignment-data-title='View Org Contact Info'
                                          onClick={() => {
                                            setOrgContactsViewingId(org.id);
                                          }}
                                        >
                                          <FA icon='phone' color='white' />
                                        </button>
                                      </div>
                                    )}
                                  </span>
                                </div>
                                {/* End iPad only actions */}
                              </div>
                            ))}

                          {ready && orgs.length === 0 && (
                            <div className='orgs_list_table_data'>
                              <div colSpan='100%'>
                                No organizations found. Try another search.
                              </div>
                            </div>
                          )}

                          {!ready && orgs.length === 0 && (
                            <div>
                              <div colSpan='100%'>
                                <FA color='gray' icon='spinner' spin />
                                <span className='ml-2'>
                                  Loading Organizations ...
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <PaginationBar
                    paginationInfo={paginationInfo}
                    fetchPageFunc={fetchAllOrgs}
                    disabled={!ready}
                    pageSize={pageSize}
                    loading={!ready}
                  />
                </div>
              </div>
            ) : (
              <p>Now how did you get in here? </p>
            )}
          </div>
        </div>
      </div>
    </MainDashboard>
  );
};

export default compose(connect(mapStateToProps), withAPI)(OrgsList);

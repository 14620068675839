import React from "react";
import FA from "../../containers/fa";
const ClosePopupBtn = ({ closePopupFunc }) => {
  return (
    <button
      autoFocus
      className='pdf_popup_close'
      onClick={() => {
        closePopupFunc(false);
      }}
    >
      <FA icon='times' />
    </button>
  );
};

export default ClosePopupBtn;

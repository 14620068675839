import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "redux";
import config from "../../common/config";
import UnassignDecoupleRobotModal from "../../components/AppModals/UnassignDecoupleRobotModal";
import OnlineNowModal from "../../components/ManageAllRobots/OnlineNowModal";
import UpdateRobotModal from "../../components/ManageAllRobots/UpdateRobotModal";
import MainDashboard from "../../partials/Main";
import withAPI from "../../services/api";

import {
  getDateTimeStringPerGivenFormat,
  removePrefix,
  timeSince,
  useTitle,
} from "../../common/utils";
import ExpirationStatus from "../../components/UtilComps/ExpirationStatus";
import SubscriptionStatus from "../../components/UtilComps/SubscriptionStatus";
import FA from "../fa";
import FAW from "../faw";
import "./RobotPage.scss";
import MultiSchoolReactSelect from "../../components/InputFields/MultiSchoolReactSelect";

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const RobotPage = ({ api, user, match }) => {
  useTitle("Robot Detail");

  const dropdownDefault = { id: 0, username: "---" };
  const [shouldSpin, setShouldSpin] = useState(true);
  const [robotInfo, setRobotInfo] = useState({});
  const [allTeachers, setAllTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [assignedTeacher, setAssignedTeacher] = useState(dropdownDefault);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [isAssigningTeacher, setIsAssigningTeacher] = useState(false);
  const [isAssigningSchool, setIsAssigningSchool] = useState(false);
  const [error, setError] = useState(null);
  const [showDecoupleConfirmModal, setShowDecoupleConfirmModal] =
    useState(false);
  const [showRobotReassignmentWarning, setShowRobotReassignmentWarning] =
    useState(false);

  const [cloudAcc, setCloudAcc] = useState(false);
  const [editable, setEditable] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canEditCanUpdate, setEditCanUpdate] = useState(false);
  const [robotNote, setRobotNote] = useState(false);
  const [editableNote, setEditableNote] = useState(false);
  const [reloadRobot, setReloadRobot] = useState(false);
  const [showUpdateRobotModal, setShowUpdateRobotModal] = useState(false);
  const [showOnlineNowModal, setShowOnlineNowModal] = useState(false);
  const [robotStatusInfo, setRobotStatusInfo] = useState({});
  const [robotStatusAge, setRobotStatusAge] = useState(null);
  const [loadingStatusInfo, setLoadingStatusInfo] = useState(true);
  const [showStatusInfo, setShowStatusInfo] = useState(false);
  const [reloadStatusTrigger, setReloadStatusTrigger] = useState(0);
  const [snapshot, setSnapshot] = useState(null);

  const accountMap = {
    true: "Cloud-enabled",
    false: "Local only",
    null: "Local only",
  };

  let history = useHistory();
  let clearTimerRef = useRef();

  const sortedArray = (arr, colname) => {
    const sorted = [...arr].sort((a, b) =>
      a[colname].localeCompare(b[colname])
    );
    return sorted;
  };

  const reloadAssignment = () => {
    api.fetchTeachersOnRobot(match.params.serial).then((resp_json) => {
      console.log("TeachersOnRobot:", resp_json.teachers);
      if (resp_json.teachers.length > 0) {
        setAssignedTeacher(resp_json.teachers[0]);
      } else {
        setAssignedTeacher(dropdownDefault);
      }
    });
  };

  const fetchSnapshot = () => {
    if (user && user.usertype === config.SUPERADMIN_USERTYPE) {
      api.fetchRobotSnapshot(match.params.serial).then((res) => {
        setSnapshot(res);
      });
    }
  };

  useEffect(() => {
    const queryParams = {
      show_all: true,
    };
    api.fetchAllVisibleUsers(queryParams).then((objects) => {
      console.log("AllVisibleUsers:", objects);
      const sorted = sortedArray(objects, "username");
      setAllTeachers(sorted);
    });
    fetchSnapshot();
  }, []);

  function fetchRobotStatus({ cacheOnly }) {
    api
      .fetchCachedRobotStatus(match.params.serial)
      .then((status_json) => {
        console.log("fetchCachedRobotStatus:", status_json);
        setRobotStatusInfo(status_json.status);
        if ((status_json && status_json.status) || cacheOnly) {
          setShowStatusInfo(true);
          setLoadingStatusInfo(false);
        }
        if (status_json && !status_json.status && !cacheOnly) {
          // If no status message in cache and robot is online,
          // try to grab status directy from the robot's status file.
          // This is slower and more complicated on the backend
          api
            .fetchRobotStatusFile(match.params.serial)
            .then((status_file_json) => {
              console.log("fetchRobotStatusFile:", status_file_json);
              setRobotStatusInfo(status_file_json.status);
              setShowStatusInfo(true);
              setLoadingStatusInfo(false);
            })
            .catch((e) => {
              console.log("fetchRobotStatusFile Error'd:", e);
              setShowStatusInfo(true);
              setLoadingStatusInfo(false);
            });
        }
      })
      .catch((fromCacheError) => {
        console.log("fetchCachedRobotStatus Error'd:", fromCacheError);
        if (cacheOnly) {
          setShowStatusInfo(true);
        }
        // But if that doesn't work, might be able to grab status directy from the robot's status file.
        if (!cacheOnly) {
          api
            .fetchRobotStatusFile(match.params.serial)
            .then((status_file_json) => {
              console.log("fetchRobotStatusFile:", status_file_json);
              setRobotStatusInfo(status_file_json.status);
              setLoadingStatusInfo(false);
              setShowStatusInfo(true);
            })
            .catch((e) => {
              console.log("fetchRobotStatusFile Error'd:", e);
              setLoadingStatusInfo(false);
              setShowStatusInfo(true);
            });
        }
      });
  }

  // Allow getOnlineStatus to grab up-to-date robotInfo, in case it changed
  const robotInfoRef = useRef(robotInfo);
  useEffect(() => {
    robotInfoRef.current = robotInfo;
  }, [robotInfo]);

  function getOnlineStatus({ primary }) {
    // Check whether this robot is online now
    api
      .fetchIsRobotOnline(match.params.serial)
      .then((online_json) => {
        let newRbtInfo = Object.assign({}, robotInfoRef.current, {
          online: online_json.online,
        });
        setRobotInfo(newRbtInfo);

        // If this robot is online, we can actively check for a status message
        // Otherwise, we must rely on the latest stored in the cache
        if (online_json.online) {
          fetchRobotStatus({ cacheOnly: false });
        } else {
          fetchRobotStatus({ cacheOnly: true });
        }
      })
      .catch((e) => {
        console.log("isRobtOnline Error'd:", e);
      });
    // Recur in 30 seconds if this is the primary loop
    if (primary) {
      clearTimerRef.current = setTimeout(function () {
        getOnlineStatus({ primary: true });
      }, 30000);
    }
  }

  useEffect(() => {
    // clear status check upon unmount
    return () => {
      console.log("CLearing currentTimeoutId: ", clearTimerRef.current);
      clearTimeout(clearTimerRef.current);
    };
  }, []);

  useEffect(() => {
    setShouldSpin(true);
    api.fetchRobotInfo(match.params.serial).then((resp_json) => {
      setShouldSpin(false);
      console.log("Got Robot info:", resp_json);
      setRobotInfo(resp_json);
      setCloudAcc(resp_json.cloud_accounts || false);
      setCanUpdate(resp_json.can_update || false);
      setRobotNote(resp_json.notes || " - ");
      setReloadStatusTrigger(reloadStatusTrigger + 1);
      setSelectedSchools(resp_json.orgunits);
    });
    reloadAssignment();
  }, [reloadRobot]);

  useEffect(() => {
    if (reloadStatusTrigger === 1) {
      // Start primary loop that will recur every 30 secs
      getOnlineStatus({ primary: true });
    } else if (reloadStatusTrigger > 1) {
      // Refresh the status info once, right now
      getOnlineStatus({ primary: false });
    }
  }, [reloadStatusTrigger]);

  useEffect(() => {
    // Whenever robotStatusInfo changes, interpret the UTC timestamp as something displayable
    if (robotStatusInfo && robotStatusInfo.timestamp) {
      let timeObj = new Date(robotStatusInfo.timestamp + "Z"); // append Z to ensure datetime gets parsed as UTC
      let delay = timeSince(timeObj);
      console.log(
        "Status posted at ",
        robotStatusInfo.timestamp,
        ", ",
        delay,
        " ago"
      );
      setRobotStatusAge(delay);
    }
  }, [robotStatusInfo]);

  useEffect(() => {
    // whenever allTeachers change, filter for teachers in the same org
    // as the robot
    if (robotInfo && robotInfo.organization_id) {
      const filtered_t = allTeachers.filter(
        (item) => item.userprofile.organization === robotInfo.organization_id
      );
      setFilteredTeachers(filtered_t);
    }
  }, [allTeachers, robotInfo]);

  function handleAssign() {
    api
      .assignTeacherToRobot([match.params.serial], selectedTeacherId)
      .then((resp) => {
        setError("");
        setIsAssigningTeacher(false);
        reloadAssignment();
      })
      .catch((e) => {
        console.log("Error'd:", e);
        if (e.message) {
          setError(e.message);
        } else {
          setError(e);
        }
      });
  }

  function reassignRobot() {
    const orgunitIds = selectedSchools.map((school) => school.id);
    api
      // TODO: replace hardcoded false with decoupleData
      .reassignRobot(
        match.params.serial,
        robotInfo.organization_id,
        false,
        orgunitIds
      )
      .then((resp) => {
        setError("");
        console.log("reassignRobot assigned Robot to School:", resp);
        setIsAssigningSchool(false);
        setReloadRobot(!reloadRobot);
      })
      .catch((e) => {
        console.log("ReassignRobot:", e);
        if (e.message) {
          setError(e.message);
        } else {
          setError(e);
        }
      });
  }

  function handleRobotSchoolAssign() {
    if (selectedSchools && assignedTeacher.id !== 0) {
      const selectedSchoolIds = new Set(selectedSchools.map((s) => s.id));
      if (
        !assignedTeacher.orgunits.some((ou) => selectedSchoolIds.has(ou[0]))
      ) {
        setShowRobotReassignmentWarning(true);
        return;
      } else {
        reassignRobot();
      }
    } else {
      reassignRobot();
    }
  }

  // TODO: move this to a separate reusable yes/no popup component
  function ReassignConfirmationPopup() {
    return (
      <>
        <div className='pdf-upload'></div>
        <div className='pdf-upload-wrapper-medium'>
          <div className='common_border'>
            <div className='common_heading' style={{ margin: "0px" }}>
              <p>{"Reassign School"}</p>
            </div>

            <div className='common_dashboard_bg' style={{ margin: "0px" }}>
              <div className='row'>
                <div className='col-12'>
                  {
                    "This Robot is assigned to a teacher that is not associated with any of the selected School(s). You may proceed, but that may not be what you intended. It is recommended that the Robot's teacher assignment matches its associated School(s)."
                  }
                  <div className='d-flex mt-4'>
                    <button
                      className='btn btn-sm btn-primary ml-4'
                      onClick={() => {
                        setShowRobotReassignmentWarning(false);
                        reassignRobot();
                      }}
                    >
                      {"Continue"}
                    </button>
                    <button
                      className='btn btn-sm btn-danger ml-4'
                      onClick={() => {
                        setShowRobotReassignmentWarning(false);
                      }}
                    >
                      {"Back"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function handleUpdate(data) {
    api
      .patchRobotInfo(match.params.serial, data)
      .then((resp) => {
        setReloadRobot(!reloadRobot);
        setEditable(false);
        setEditableNote(false);
        setEditCanUpdate(false);
      })
      .catch((e) => {
        console.log("patchRobot Error'd:", e);
        setError(e);
      });
  }

  function convertTimestamp(timeString) {
    if (timeString) {
      return new Date(Date.parse(timeString)).toLocaleString();
    } else {
      return null;
    }
  }

  const getButtonDiv = () => {
    if (!robotInfo.can_update) {
      return (
        <button
          className='btn btn-md btn-secondary'
          style={{ fontSize: "24px" }}
          disabled={true}
        >
          <FAW icon='sync' />
          &nbsp;{"Subscription Expired"}
        </button>
      );
    } else if (robotStatusInfo) {
      return (
        <div className='status-button p-1'>
          <button
            className='btn btn-md btn-primary'
            style={{ fontSize: "24px" }}
            disabled={!robotStatusInfo.update_allowed}
            onClick={() => {
              setShowUpdateRobotModal(true);
            }}
          >
            <FAW icon='sync' />
            &nbsp;{"Update and Sync"}
          </button>
        </div>
      );
    } else {
      return (
        <div className='status-button'>
          <button
            className='btn btn-sm btn-primary'
            style={{ fontSize: "24px" }}
            onClick={() => {
              setShowUpdateRobotModal(true);
            }}
          >
            <FAW icon='sync' />
            &nbsp;{"Update and Sync"}
          </button>
        </div>
      );
    }
  };

  const handleSchoolChange = (selectedSchools) => {
    if (selectedSchools && selectedSchools.length > 0) {
      setSelectedSchools(
        selectedSchools.map((option) => ({
          name: option.label,
          id: option.value,
        }))
      );
    } else {
      setSelectedSchools([]);
    }
  };

  const premiumcare_sub = robotInfo?.registrationcode?.premiumcare_sub;

  function renderWifiCapabilities(robotInfo) {
    switch (robotInfo.wifi_dongle) {
      case "0bda:8179":
        return (
          <React.Fragment>
            {"Broadcast on 2.4 GHz."}
            <br />
            {"Connect only on 2.4 GHz."}
          </React.Fragment>
        );
      case "0bda:c811":
        if (robotInfo.sbc === "4B") {
          return (
            <React.Fragment>
              {"Broadcast on 5 GHz."}
              <br />
              {"Connect on 2.4 GHz or 5 GHz."}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              {"Broadcast on 2.4 GHz."}
              <br />
              {"Connect on 2.4 GHz or 5 GHz."}
            </React.Fragment>
          );
        }
      case "0bda:b812":
        if (robotInfo.sbc === "4B") {
          return (
            <React.Fragment>
              {"Broadcast on 5 GHz."}
              <br />
              {"Connect on 2.4 GHz or 5 GHz."}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              {"Broadcast on 5 GHz."}
              <br />
              {"Connect on 2.4 GHz."}
            </React.Fragment>
          );
        }
      default:
        return null;
    }
  }

  return (
    <div>
      <MainDashboard>
        <div className='common_border' style={{ position: "relative" }}>
          <div className='common_heading d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-left'>
              {showRobotReassignmentWarning && ReassignConfirmationPopup()}
              <h4>Robot Info</h4>
              {robotInfo.serial && (
                <React.Fragment>
                  &nbsp;&nbsp;<h4>{" - " + robotInfo.wifi}</h4>
                  {/* LOADING status */}
                  {loadingStatusInfo && (
                    <React.Fragment>
                      &nbsp;&nbsp;&nbsp;
                      <FA color='gray' icon='spinner' spin />
                      &nbsp;{"Checking robot status..."}
                    </React.Fragment>
                  )}
                  {/* OFFLINE status shows nothing*/}
                  {/* ONLINE status */}
                  {!loadingStatusInfo && robotInfo.online && showStatusInfo && (
                    <React.Fragment>
                      &nbsp;
                      <FA
                        color='green'
                        icon='circle'
                        style={{ cursor: "pointer" }}
                        /*onClick={() => setShowUpdateRobotModal(true)}*/
                        onClick={() => setShowOnlineNowModal(true)}
                        className='faa-pulse animated fa-xs'
                      />
                      &nbsp;&nbsp;
                      <h4 style={{ color: "green" }}>{"Currently Online"}</h4>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
            &nbsp;
            <button
              className='btn btn-sm btn-primary'
              onClick={() => history.goBack()}
            >
              {" "}
              Back{" "}
            </button>
          </div>

          <div className='robot_page_bg'>
            {showUpdateRobotModal && (
              <UpdateRobotModal
                rbt={robotInfo}
                shouldShowFunc={setShowUpdateRobotModal}
                statusReloadFunc={() => {
                  setTimeout(() => {
                    setReloadStatusTrigger(reloadStatusTrigger + 1);
                  }, 3000);
                }}
              />
            )}

            {showOnlineNowModal && (
              <OnlineNowModal
                rbt={robotInfo}
                shouldShowFunc={setShowOnlineNowModal}
                setShowUpdateRobotModal={setShowUpdateRobotModal}
                statusReloadFunc={() => {
                  setTimeout(() => {
                    setReloadStatusTrigger(reloadStatusTrigger + 1);
                  }, 3000);
                }}
              />
            )}

            <div className='row'>
              <div className='col-12'>
                {error && (
                  <div className='alert alert-danger' role='alert'>
                    {error}
                  </div>
                )}
                {shouldSpin && (
                  <div>
                    <h4>
                      <FA color='gray' icon='spinner' spin /> Loading Details...
                    </h4>
                  </div>
                )}

                {/* New "row" for status info */}
                {!shouldSpin && robotInfo.online && (
                  <React.Fragment>
                    {robotInfo && robotInfo.online && showStatusInfo && (
                      <div className='status-container'>
                        <div className='status-text d-flex align-items-center'>
                          <div>
                            {robotStatusInfo && robotStatusInfo.message && (
                              <React.Fragment>
                                {"Latest Status:"}
                                <br />
                                {robotStatusInfo.message}
                                {"."}&nbsp;{" ("}
                                {robotStatusAge}
                                {" ago)"}
                              </React.Fragment>
                            )}
                          </div>
                        </div>

                        {getButtonDiv()}

                        <div className='status-blank'></div>
                      </div>
                    )}
                  </React.Fragment>
                )}

                {!shouldSpin && (
                  <div className='card mb-1'>
                    <div
                      className='progress-linear school_listing manage_learner_table'
                      role='progressbar'
                    >
                      <table
                        className='table table-sm table-striped'
                        style={{ padding: "0.5rem" }}
                      >
                        <tbody>
                          <tr key='Teacher'>
                            <td>
                              <span style={{ alignItems: "center" }}>
                                Teacher
                              </span>
                            </td>
                            <td>
                              {isAssigningTeacher ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <select
                                    value={selectedTeacherId}
                                    onChange={(e) =>
                                      setSelectedTeacherId(e.target.value)
                                    }
                                    className='teacherDropdown form-control input-underline'
                                  >
                                    <option
                                      key={dropdownDefault.id}
                                      value={dropdownDefault.id}
                                    >
                                      {dropdownDefault.username}
                                    </option>
                                    {filteredTeachers.map((t) => (
                                      <option key={t.id} value={t.id}>
                                        {removePrefix(
                                          t.username,
                                          "placeholder+"
                                        )}
                                      </option>
                                    ))}
                                  </select>
                                  <div>
                                    <button
                                      className='btn btn-sm btn-primary'
                                      onClick={handleAssign}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className='btn btn-sm btn-danger'
                                      onClick={() =>
                                        setIsAssigningTeacher(false)
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex justify-content-between align-items-center'>
                                  <p>
                                    {assignedTeacher &&
                                      removePrefix(
                                        assignedTeacher.username,
                                        "placeholder+"
                                      )}
                                  </p>
                                  {user &&
                                    user.usertype !==
                                      config.TEACHER_USERTYPE && (
                                      <button
                                        className='btn btn-sm btn-primary'
                                        onClick={() =>
                                          setIsAssigningTeacher(true)
                                        }
                                      >
                                        Edit
                                      </button>
                                    )}
                                </div>
                              )}
                            </td>
                          </tr>
                          {robotInfo.assignment && (
                            <tr key='School'>
                              <td>
                                <span style={{ alignItems: "center" }}>
                                  School
                                </span>
                              </td>
                              <td>
                                {isAssigningSchool ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ flex: 1 }}>
                                      <MultiSchoolReactSelect
                                        value={selectedSchools}
                                        handleChange={handleSchoolChange}
                                        orgId={
                                          user &&
                                          user.usertype ===
                                            config.SUPERADMIN_USERTYPE
                                            ? robotInfo.organization_id
                                            : null
                                        }
                                        className='schoolDropdown'
                                      />
                                    </div>
                                    <button
                                      className='btn btn-sm btn-primary'
                                      onClick={handleRobotSchoolAssign}
                                      disabled={
                                        user &&
                                        user.usertype ===
                                          config.ORGUNITADMIN_USERTYPE &&
                                        selectedSchools.length === 0
                                      }
                                    >
                                      Save
                                    </button>
                                    <button
                                      className='btn btn-sm btn-danger'
                                      onClick={() => {
                                        setIsAssigningSchool(false);
                                        setSelectedSchools(robotInfo.orgunits);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                ) : (
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <span>
                                      {robotInfo.assignment.school
                                        ? robotInfo.assignment.school
                                        : "---"}
                                    </span>
                                    {user &&
                                      user.usertype !==
                                        config.TEACHER_USERTYPE && (
                                        <button
                                          className='btn btn-sm btn-primary'
                                          onClick={() =>
                                            setIsAssigningSchool(true)
                                          }
                                        >
                                          Edit
                                        </button>
                                      )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                          <tr key='Wifi'>
                            <td>
                              <span>Wifi</span>
                            </td>
                            <td>
                              <span>{robotInfo.wifi}</span>
                            </td>
                          </tr>
                          <tr key='Serial'>
                            <td>
                              <span>Serial</span>
                            </td>
                            <td>
                              <span>{robotInfo.serial}</span>
                            </td>
                          </tr>
                          <tr key='MAC'>
                            <td>
                              <span>MAC</span>
                            </td>
                            <td>
                              <span>{robotInfo.mac}</span>
                            </td>
                          </tr>
                          <tr key='codeVer'>
                            <td>
                              <span>Code Version</span>
                            </td>
                            <td>
                              <span className='mr-1'>{robotInfo.code_ver}</span>
                              {robotInfo.is_latest ? (
                                <span data-title='Software Up-to-date'>
                                  <FA color='green' icon='check-circle' />
                                </span>
                              ) : (
                                <span data-title='Update Available'>
                                  <FA
                                    color='#ffc107'
                                    icon='exclamation-circle'
                                  />
                                </span>
                              )}
                            </td>
                          </tr>
                          {user &&
                            user.usertype === config.SUPERADMIN_USERTYPE &&
                            robotInfo.organization_id && (
                              <tr key='org'>
                                <td>
                                  <span>Organization</span>
                                </td>
                                <td>
                                  <Link
                                    to={`/super/org/${robotInfo.organization_id}`}
                                  >
                                    {robotInfo.assignment.organization ||
                                      "Organization Name Unavailable"}
                                  </Link>
                                  {robotInfo.assignment.organization_type && (
                                    <span className='ml-2'>
                                      ({robotInfo.assignment.organization_type})
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )}
                          {user &&
                            user.usertype === config.SUPERADMIN_USERTYPE && (
                              <tr key='batch'>
                                <td>
                                  <span>Batch</span>
                                </td>
                                <td>{robotInfo.batch_name}</td>
                              </tr>
                            )}
                          {user &&
                            user.usertype === config.SUPERADMIN_USERTYPE && (
                              <tr key='sbc'>
                                <td>
                                  <span>SBC</span>
                                </td>
                                <td>{robotInfo.sbc}</td>
                              </tr>
                            )}
                          {user &&
                            user.usertype === config.SUPERADMIN_USERTYPE && (
                              <tr key='wifi_capabilities'>
                                <td>
                                  <span>Wifi Capabilities</span>
                                </td>
                                <td>{renderWifiCapabilities(robotInfo)}</td>
                              </tr>
                            )}
                          {user &&
                            user.usertype === config.ORGADMIN_USERTYPE && (
                              <tr key='cloud_accounts'>
                                <td>
                                  <span>Account Type</span>
                                </td>
                                <td>
                                  <div className='d-flex justify-content-between common-align-button action-btns'>
                                    {accountMap[robotInfo.cloud_accounts]}
                                  </div>
                                </td>
                              </tr>
                            )}
                          {user &&
                            user.usertype === config.SUPERADMIN_USERTYPE && (
                              // make this into a component
                              <tr key='cloud_accounts'>
                                <td>
                                  <span>Cloud Accounts?</span>
                                </td>
                                {editable ? (
                                  <td>
                                    <div className='d-flex justify-content-between common-align-button action-btns'>
                                      <input
                                        type='checkbox'
                                        checked={cloudAcc}
                                        onChange={(e) => {
                                          setCloudAcc(!cloudAcc);
                                        }}
                                      />
                                      <div className='d-flex align-items-end common-align-button'>
                                        <button
                                          className='btn btn-sm btn-primary ml-2'
                                          onClick={() =>
                                            handleUpdate({
                                              cloud_accounts: cloudAcc,
                                            })
                                          }
                                        >
                                          Save
                                        </button>
                                        <button
                                          className='btn btn-sm btn-danger ml-2'
                                          onClick={() => setEditable(false)}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td>
                                    <div className='d-flex justify-content-between common-align-button action-btns'>
                                      {accountMap[robotInfo.cloud_accounts]}
                                      <button
                                        className='btn btn-sm btn-primary ml-2'
                                        onClick={() => setEditable(true)}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            )}
                          <tr key='activation_time'>
                            <td>
                              <span>Activated On</span>
                            </td>
                            <td>
                              {convertTimestamp(robotInfo.activated_on) ||
                                " -- "}
                            </td>
                          </tr>
                          <tr key='last_synced'>
                            <td>
                              <span>Last Online Check-in:</span>
                            </td>
                            <td>
                              {convertTimestamp(robotInfo.last_synced_on)}
                            </td>
                          </tr>
                          <tr key='last_update'>
                            <td>
                              <span>Last Code Update:</span>
                            </td>
                            <td>
                              {convertTimestamp(robotInfo.last_code_update)}
                            </td>
                          </tr>
                          <tr key='last_lesson_update'>
                            <td>
                              <span>Last Lesson Update:</span>
                            </td>
                            <td>
                              {convertTimestamp(robotInfo.last_lesson_update)}
                            </td>
                          </tr>

                          <tr key='premiumcare_sub_end_date'>
                            <td>
                              <span>Expiration Date:</span>
                            </td>
                            <td>
                              <ExpirationStatus obj={robotInfo} />
                            </td>
                          </tr>

                          {user &&
                            user.usertype === config.SUPERADMIN_USERTYPE && (
                              //make this a component
                              <>
                                <tr key='cloud_accounts'>
                                  <td>
                                    <span>Can Update?</span>
                                  </td>
                                  {canEditCanUpdate ? (
                                    <td>
                                      <div className='d-flex justify-content-between common-align-button action-btns'>
                                        <input
                                          type='checkbox'
                                          checked={canUpdate}
                                          onChange={(e) => {
                                            setCanUpdate(!canUpdate);
                                          }}
                                        />
                                        <div className='d-flex align-items-end common-align-button'>
                                          <button
                                            className='btn btn-sm btn-primary ml-2'
                                            onClick={() =>
                                              handleUpdate({
                                                can_update: canUpdate,
                                              })
                                            }
                                          >
                                            Save
                                          </button>
                                          <button
                                            className='btn btn-sm btn-danger ml-2'
                                            onClick={() =>
                                              setEditCanUpdate(false)
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  ) : (
                                    <td>
                                      <div className='d-flex justify-content-between common-align-button action-btns'>
                                        {canUpdate ? "Yes" : "No"}
                                        <button
                                          className='btn btn-sm btn-primary ml-2'
                                          onClick={() => setEditCanUpdate(true)}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                                {robotInfo.registrationcode && (
                                  <>
                                    <tr key='registration_code'>
                                      <td>
                                        <span>Registration Code:</span>
                                      </td>
                                      <td>
                                        {robotInfo.registrationcode.code ||
                                          "--"}
                                      </td>
                                    </tr>
                                    {premiumcare_sub && (
                                      <>
                                        <tr key='premiumcare_sub_id'>
                                          <td>
                                            <span>Subscription Id:</span>
                                          </td>
                                          <td>{premiumcare_sub.id}</td>
                                        </tr>
                                        <tr key='premiumcare_sub_active'>
                                          <td>
                                            <span>Subscription Status:</span>
                                          </td>
                                          <td>
                                            <SubscriptionStatus
                                              subscription={premiumcare_sub}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                  </>
                                )}
                                <tr key='notes'>
                                  <td>
                                    <span>Notes</span>
                                  </td>
                                  {editableNote ? (
                                    <td>
                                      <div className='d-flex justify-content-between common-align-button action-btns'>
                                        <TextField
                                          style={{ width: "100%" }}
                                          multiline
                                          minRows={3}
                                          maxRows={5}
                                          value={robotNote}
                                          onChange={(e) => {
                                            setRobotNote(e.target.value);
                                          }}
                                        />
                                        <div className='d-flex align-items-end common-align-button'>
                                          <button
                                            className='btn btn-sm btn-primary ml-2'
                                            onClick={() =>
                                              handleUpdate({ notes: robotNote })
                                            }
                                          >
                                            Save
                                          </button>
                                          <button
                                            className='btn btn-sm btn-danger ml-2'
                                            onClick={() => {
                                              setEditableNote(false);
                                              setRobotNote(robotInfo.notes);
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  ) : (
                                    <td>
                                      <div className='d-flex justify-content-between common-align-button action-btns'>
                                        {robotNote}
                                        <button
                                          className='btn btn-sm btn-primary ml-2'
                                          onClick={() => setEditableNote(true)}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              </>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {!shouldSpin &&
                  user &&
                  user.usertype === config.SUPERADMIN_USERTYPE && (
                    <div className='text-right'>
                      <button
                        className='btn btn-sm btn-danger'
                        onClick={() => {
                          setShowDecoupleConfirmModal(true);
                        }}
                      >
                        Decouple Data from Robot
                      </button>
                    </div>
                  )}

                {!shouldSpin && snapshot && snapshot.snapshot_time && (
                  <div className='mt-4'>
                    <div className='d-flex'>
                      <h4>Robot Snapshot</h4>
                      <p className='my-auto ml-3'>
                        Snapshot taken on:
                        <span className='ml-2'>
                          {getDateTimeStringPerGivenFormat(
                            snapshot.snapshot_time,
                            "M/D/YYYY h:mma"
                          )}
                        </span>
                      </p>
                    </div>
                    <div className='mt-2'>
                      <h5>Teachers</h5>
                      {snapshot.teachers.length > 0 ? (
                        snapshot.teachers.map((teacher, ind) => (
                          <p className='mb-0'>
                            {ind + 1}. {teacher}
                          </p>
                        ))
                      ) : (
                        <p>No Teachers in snapshot.</p>
                      )}
                    </div>
                    <div className='mt-2'>
                      <h5>Learners</h5>
                      {snapshot.learners.length > 0 ? (
                        snapshot.learners.map((learner, ind) => (
                          <p className='mb-0'>
                            {ind + 1}. {learner}
                          </p>
                        ))
                      ) : (
                        <p>No learners in snapshot.</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showDecoupleConfirmModal && (
          <UnassignDecoupleRobotModal
            useCase={"decouple"}
            rbtSerial={match.params.serial}
            shouldShowFunc={setShowDecoupleConfirmModal}
            handleConfirmSuccess={() => {
              fetchSnapshot();
              setShowDecoupleConfirmModal(false);
            }}
          />
        )}
      </MainDashboard>
    </div>
  );
};

export default compose(connect(mapStateToProps), withAPI)(RobotPage);
